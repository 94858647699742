$default-font: "Roboto", sans-serif;
$accent: orange;
$text: #333;
$linkColor: #1976d2;
$transitionTime: 0.35s;

%full-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Basic CSS styles
@import "vars";

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%
}

::placeholder {
  color: #666
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text;

  input, textarea {
    border: #666 1px solid;
    outline: none;

    &:focus:required:invalid {
      border-color: red;
    }

    &:required:valid {
      border-color: green;
    }
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;

  &.img-width {
    width: 100%
  }
}

b, strong {
  font-weight: bold
}

a {
  color: $linkColor;
  transition: color $transitionTime;

  &:hover {
    color: $linkColor
  }

  &:focus {
    outline: none;
  }
}

#root {
  height: 100vh;
}

@font-face {
  font-family: "Optimus";
  src: url("../fonts/optimus.eot");
  src: local(Optimus),
  url("../fonts/optimus.woff") format("woff"),
  url("../fonts/optimus.ttf") format("truetype"),
  url("../fonts/optimus.svg#optimusregular") format("svg");
}

@font-face {
  font-family: "EnigmaU";
  src: url("../fonts/enigma-webfont.eot");
  src: local(EnigmaU),
  url("../fonts/enigma-webfont.woff") format("woff"),
  url("../fonts/enigma-webfont.ttf") format("truetype"),
  url("../fonts/enigma-webfont.svg#enigmaregular") format("svg");
}

.app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: stretch;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -9999;
}
